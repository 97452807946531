export const titleSteps = [
  'Login',
  'Registro de datos',
  'Confirmación de teléfono',
  'Documento de identificación',
];

export const KYC_VALUES = {
  NEW: 'new',
  PROCESSING: 'processing',
  POST_PROCESSING: 'post_processing',
  HOLD: 'hold',
  FAILED: 'failed',
  RESTARTED: 'restarted',
  COMPLETED: 'completed',
  CUSTOMER_REVIEW: 'customer_review',
};

export const PARTIAL_SUCCESS = {
  title: 'Registro Recibido',
  description:
    'Hemos recibido tus datos, el siguiente paso es verificar tu información, cuando el proceso finalice se te notificará por medio de un mensaje de texto',
};

export const PROCESSING = {
  title: 'Registro En Proceso',
  description:
    'Tus datos están siendo procesados, cuando el proceso finalice se te notificará por medio de un mensaje de texto',
};

export const SUCCESS = {
  title: 'Registro Exitoso',
  description:
    'El registro se ha completado de manera exitosa, a partir de este momento ya puede realizar transacciones con nosotros',
};

export const FAILED_MESSAGE = {
  severity: 'warn',
  summary: '',
  detail:
    'Hemos tenido problemas al momento de verificar tu perfil, te invitamos a que sigas los pasos una vez más y te asegures de ingresar información válida.',
};

export const MESSAGE_SERVICE = {
  error_phone_confirmation:
    'Algo salió mal al momento de verificar tu número de teléfono, asegúrate de ingresar el código correcto.',
  invalid_confirmation_code:
    'El código ingresado ya no es válido, por favor solicita uno nuevo',
  error_save_data:
    'Ocurrió un problema al intentar guardar tus datos, vuelve a intentarlo o comunícate con el administrador.',
  new_confirmation_code: 'Se ha enviado un código de verificación al número',
  confirmation_code_no_send:
    'Algo salió mal y no pudimos reenviar tu código de verificación',
  load_area_code_failed: 'No se pudo cargar la lista de códigos de área.',
};
