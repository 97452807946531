/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.


import { environment } from './environments/environment';


const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "kycform",
            "endpoint": environment.api_endpoint_kyc,
            "region": environment.region
        }
    ],
    "aws_cognito_identity_pool_id": environment.aws_cognito_identity_pool_id,
    "aws_cognito_region": environment.region,
    "aws_user_pools_id": environment.aws_user_pools_id,
    "aws_user_pools_web_client_id": environment.aws_user_pools_web_client_id
};


export default awsmobile;
