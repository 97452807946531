<div
  class="flex-col sm:flex-row flex px-5 pb-5 pt-5 sm:pt-0 justify-between sm:items-center gap-8 min-h-[272px] sm:min-h-[122px]"
  *ngIf="progress.length !== 0 && title.length !== 0"
>
  <div
    class="flex-col sm:flex-row flex justify-center sm:justify-start items-center gap-3 min-h-[122px]"
  >
    <circle-progress
      [percent]="progress[step - 1].porcent"
      [radius]="34"
      [toFixed]="0"
      [showTitle]="true"
      [showInnerStroke]="true"
      [showZeroOuterStroke]="true"
      [startFromZero]="false"
      [responsive]="false"
      [showBackground]="false"
      [showImage]="false"
      [showSubtitle]="false"
      [showUnits]="false"
      [space]="-7"
      [outerStrokeWidth]="7"
      [innerStrokeWidth]="7"
      [backgroundStrokeWidth]="0"
      [outerStrokeLinecap]="'round'"
      [outerStrokeColor]="'#FFC04A'"
      [innerStrokeColor]="'white'"
      [title]="progress[step - 1].label"
      [titleFontSize]="'15'"
      [titleFontWeight]="'bold'"
      [titleColor]="'#FFC04A'"
    ></circle-progress>
    <p class="mb-0 pl-3 text-[#FFC04A] font-semibold text-3xl text-center">
      {{ title }}
    </p>
  </div>
  <div class="previous-button-text flex justify-center">
    <img
      src="assets/img/athena_bitcoin_logo_white.png"
      alt="Athena.png"
      width="80"
      class="hover:cursor-pointer"
      [routerLink]="['/']"
    />
  </div>
</div>
