<p class="text-2xl text-center pb-5 text-athena-orange font-medium col-span-4">
  Código de verificación
</p>
<p class="pb-5 text-justify col-span-4">
  Un código de verificación ha sido enviado por medio de un mensaje de texto al
  número proporcionado, por favor, ingrese el código sin espacios y caracteres
  en el siguiente campo.
</p>
<form [formGroup]="codeForm" (submit)="confirmSMSCode()">
  <div class="grid grid-cols-4 gap-y-5 gap-x-3">
    <div class="flex flex-col gap-3 col-span-4">
      <input
        #otp
        pInputText
        formControlName="sms"
        type="text"
        name="sms"
        id="sms"
        appConfirmationCode
        class="text-center text-2xl"
        style="letter-spacing: 0.5em"
        placeholder="___ ___"
        (input)="onChangeOtp($event)"
        autofocus
      />
      <span
        class="p-error w-full pl-1"
        *ngIf="
          (codeForm.get('sms')?.hasError('minlength') &&
            codeForm.get('sms')?.touched) ||
          (codeForm.get('sms')?.touched && codeForm.get('sms')?.invalid)
        "
      >
        Ingrese un código de verificación válido
      </span>
    </div>

    <div
      class="flex-col sm:flex-row justify-center sm:justify-between gap-3"
      [ngClass]="login ? 'flex col-span-4 sm:col-span-2' : 'hidden'"
    >
      <p-button
        (onClick)="cancelConfirmSignIn()"
        [style]="{ width: '100%' }"
        label="Regresar"
        class="w-full"
        styleClass="p-button-secondary"
      ></p-button>
    </div>
    <div
      class="flex flex-col sm:flex-row justify-center sm:justify-between gap-3"
      [ngClass]="login ? 'flex col-span-4 sm:col-span-2' : 'col-span-4'"
    >
      <p-button
        type="submit"
        [loading]="loading"
        [style]="{ width: '100%' }"
        class="w-full"
        label="Siguiente"
        styleClass="p-button"
      ></p-button>
    </div>
    <div
      class="col-span-4 w-full flex flex-col md:flex-row justify-center items-center gap-3"
    >
      <p class="text-center text-sm">¿El código no ha llegado?</p>
      <div class="flex items-center gap-2">
        <p
          class="text-sm"
          (click)="resendCode()"
          [ngClass]="
            confirmationCodeService.resendBtnDisabled
              ? 'text-slate-400 cursor-not-allowed'
              : 'text-athena-yellow cursor-pointer underline'
          "
          [ngStyle]="{
            'pointer-events': confirmationCodeService.resendBtnDisabled
              ? 'none'
              : null
          }"
        >
          Obtener un nuevo código
        </p>
        <p
          class="font-normal text-sm"
          *ngIf="confirmationCodeService.counter > 0"
        >
          {{ confirmationCodeService.counter }}
        </p>
      </div>
    </div>
  </div>
</form>
