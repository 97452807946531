import { Component, Input } from '@angular/core';
export type TypeIcon = 'error' | 'success' | 'warning';
@Component({
  selector: 'app-icon-animation',
  templateUrl: './icon-animation.component.html',
  styleUrls: ['./icon-animation.component.scss'],
})
export class IconAnimationComponent {
  @Input() typeIcon: TypeIcon = 'success';
}
