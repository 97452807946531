import { Directive, HostListener, Input } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { GlobalService } from 'src/app/services/global.service';

@Directive({
  selector: '[formControlName][appPhoneMask]',
})
export class PhoneMaskDirective {
  phoneUtil = PhoneNumberUtil.getInstance();
  PNF = require('google-libphonenumber').PhoneNumberFormat;
  @Input('appPhoneMask') areaCode: AbstractControl | null;
  subscription: any = null;
  phoneNumber: string;
  googlePhoneNumberFormat: any;
  formatValue: string;
  constructor(
    public ngControl: NgControl,
    private globalService: GlobalService
  ) {}

  @HostListener('ngModelChange', ['$event'])
  onInputChange(event: any) {
    // Delete all characters that not be numbers
    this.phoneNumber = event.replace(/\D/g, '');
    try {
      // * This block is executed every time the value of area code field change
      if (this.areaCode) {
        this.globalService.addSubscription(
          this.areaCode?.valueChanges.subscribe((value) => {
            if (this.phoneNumber.length > 0) {
              // create a phone number of libphonenumber.PhoneNumber type
              this.googlePhoneNumberFormat =
                this.phoneUtil.parseAndKeepRawInput(
                  this.phoneNumber,
                  value ? value.code : 'SV'
                );

              if (value) {
                // format the phone number with the code country selected
                this.formatValue = this.phoneUtil.formatInOriginalFormat(
                  this.googlePhoneNumberFormat,
                  value.code
                );
              } else {
                // format the phone number with the code country by default
                this.formatValue = this.phoneUtil.format(
                  this.googlePhoneNumberFormat,
                  'SV' as unknown as PhoneNumberFormat
                );
              }

              // Assign the phone number to the phone filed
              this.ngControl?.valueAccessor?.writeValue(this.formatValue);
            } else {
              this.ngControl?.valueAccessor?.writeValue(this.phoneNumber);
            }
          })
        );
      }

      // * This block is executed when the user enter a phone with or without a area code
      if (this.phoneNumber.length > 0) {
        // create a phone number of libphonenumber.PhoneNumber type
        this.googlePhoneNumberFormat = this.phoneUtil.parseAndKeepRawInput(
          this.phoneNumber,
          this.areaCode && this.areaCode.value ? this.areaCode.value.code : 'SV'
        );

        if (this.areaCode && this.areaCode.value) {
          // format the phone number with the code country selected
          this.formatValue = this.phoneUtil.formatInOriginalFormat(
            this.googlePhoneNumberFormat,
            this.areaCode.value.code
          );
        } else {
          // format the phone number with the code country by default
          this.formatValue = this.phoneUtil.format(
            this.googlePhoneNumberFormat,
            'SV' as unknown as PhoneNumberFormat
          );
        }

        // Assign the phone number to the phone filed
        this.ngControl?.valueAccessor?.writeValue(this.formatValue);
      } else {
        this.ngControl?.valueAccessor?.writeValue(this.phoneNumber);
      }
    } catch (error: any) {
      this.ngControl?.valueAccessor?.writeValue(this.phoneNumber);
      this.ngControl?.control?.setErrors({ invalid: true });
    }
  }
}
