import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appEmptyString]',
})
export class EmptyStringDirective {
  constructor(public ngControl: NgControl) {}

  @HostListener('ngModelChange', ['$event'])
  removeEmptyString(event: string): void {
    if (event) {
      const string = event.trim();
      if (string.length > 0) {
        this.ngControl.valueAccessor?.writeValue(event.trimStart());
      } else {
        this.ngControl.control?.setValue('');
      }
    }
  }
}
