import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appConfirmationCode]',
})
export class ConfirmationCodeDirective {
  constructor(public ngControl: NgControl) {}

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event: any) {
    this.onInputChange(event);
  }

  onInputChange(event: any) {
    let newVal = event.replace(/\D/g, '');
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '$1');
    } else {
      newVal = newVal.substring(0, 6);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1 $2');
    }
    if (this.ngControl.valueAccessor)
      this.ngControl.valueAccessor.writeValue(newVal);
  }
}
