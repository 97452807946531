



<footer class="footer">
  <div class="Footer_Page">
    <div class="row">
      <div class="footer-col">
        <h4>Company</h4>
        <ul>
          <li><a href="https://athenabitcoin.com/about/">About
          </a></li>
          <li><a href="https://athenabitcoin.com/about/">Investors</a></li>
          <li><a href="https://athenabitcoin.com/press-and-media/">Press & Media</a></li>
          <li><a href="https://athenabitcoin.com/careers/">Careers</a></li>
          <li><a href="https://athenabitcoin.com/Commitment-to-Security/">Commitment to Security</a></li>
        </ul>
      </div>
      <div class="footer-col">
        <h4>Focus Areas</h4>
        <ul>
          <li><a href="https://athenabitcoin.com/ruru/">Ruru</a></li>
          <li><a href="https://athenabitcoin.com/experience-center/">ABIT Experience Center</a></li>
          <li><a href="https://www.athenacryptoexchange.com/">ACE</a></li>
          <li><a href="https://www.athenacryptoexchange.com/ace-vida-sv/">ACE | Vida SV</a></li>
          <li><a href="https://athenabitcoin.com/athena-pay/">Athena Pay</a></li>
          <li><a href="https://athenabitcoin.com/locations-map">ATMs</a></li>
        </ul>
      </div>
      <div class="footer-col">
        <h4>ATM Information</h4>
        <ul>
          <li><a href="https://athenabitcoin.com/atm-information/">ATM Information</a></li>
          <li><a href="https://athenabitcoin.com/atm-locations/">ATM Locations</a></li>
          <li><a href="https://athenabitcoin.com/avoid-these-bitcoin-scams/">Avoid These Scams</a></li>
          <li><a href="https://athenabitcoin.com/faq/">FAQ</a></li>
        </ul>
      </div>


    <div class="footer-col">
      <h4>Contact</h4>
      <ul>
        <li><a href="https://athenabitcoin.com/contact-us/">Contact Us</a></li>
        <li><a href="https://support.athenabitcoin.com/en/support/tickets/new">Customer Support</a></li>
        <li><a href="https://twitter.com/AthenaBitcoin">Twitter</a></li>
        <li><a href="https://www.linkedin.com/authwall?trk=gf&trkInfo=AQHIUjU-pJTchgAAAYYO_Mzw4cr4lU-LgSrUX1foxylKXwTXOccWVemhVJGjl9zz3v_7qUi5fSbECtCWjA45f5-e9tRKVRuISJpqutaUh10kxoCXLO7SDEuppwqWTiTG8HwAar4=&original_referer=https://athenabitcoin.com/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fathenabitcoin%2F">LinkedIn</a></li>
        <li><a href="https://www.instagram.com/athenabitcoin/">Instagram</a></li>
      </ul>

    </div>

  </div>
<br>
<br>
<br>
  <p class="paragraph">Copyright 2021 Athena Bitcoin Global, All Rights Reserved</p>
<br>
  <p class="paragraph">Privacy Policy</p>
<br>
  <p style="font-size: 0.8rem;">Version: {{ version }}</p>
</div>
</footer>


<!-- Comienza el Footer de Dani


  <footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-6 col-md-2 mb-3">
        <h2 class="textcolor">Compañía</h2>
        <ul class="nav flex-column">
          <li><button type="button" class="btn btn-link" (click)="redirect('A1')">Sobre nosotros</button></li>
          <li><button type="button" class="btn btn-link" (click)="redirect('A2')">Inversionistas</button></li>
          <li><button type="button" class="btn btn-link" (click)="redirect('A3')">Carreras</button></li>
          <li><button type="button" class="btn btn-link" (click)="redirect('A4')">Prensa y medios</button></li>
          <li><button type="button" class="btn btn-link" (click)="redirect('A5')">Compromiso a la seguridad</button></li>
          <li><button type="button" class="btn btn-link" (click)="redirect('A6')">Política Tratamiento de Datos</button></li>
        </ul>
      </div>
      <div class="col-6 col-md-2 mb-3">
        <h2 class="textcolor">Áreas de Enfoque</h2>
        <ul class="nav flex-column">
          <li><button type="button" class="btn btn-link" (click)="redirect('B1')">Ruru</button></li>
          <li><button type="button" class="btn btn-link" (click)="redirect('B2')">Cajeros</button></li>
          <li><button type="button" class="btn btn-link" (click)="redirect('B3')">ACE</button></li>
          <li><button type="button" class="btn btn-link" (click)="redirect('B4')">ACE | Vida SV</button></li>
          <li><button type="button" class="btn btn-link" (click)="redirect('B5')">ABIT Experience Center</button></li>
          <li><button type="button" class="btn btn-link" (click)="redirect('B6')">Athena Pay</button></li>
        </ul>
      </div>
      <div class="col-6 col-md-2 mb-3">
        <h2 class="textcolor">Información</h2>
        <ul class="nav flex-column">
          <li><button type="button" class="btn btn-link" (click)="redirect('C1')">Información de cajeros</button></li>
          <li><button type="button" class="btn btn-link" (click)="redirect('C2')">Mapa de cajeros</button></li>
          <li><button type="button" class="btn btn-link" (click)="redirect('C3')">Preguntas Frecuentes</button></li>
        </ul>
      </div>
      <div class="col-6 col-md-2 mb-3">
        <h2 class="textcolor">Contacto</h2>
        <ul class="nav flex-column">
          <li><button type="button" class="btn btn-link" (click)="redirect('D1')">Contacto</button></li>
          <li><button type="button" class="btn btn-link" (click)="redirect('D2')">Soporte al cliente</button></li>
          <li><button type="button" class="btn btn-link" (click)="redirect('D3')">Twitter</button></li>
          <li><button type="button" class="btn btn-link" (click)="redirect('D4')">LinkedIn</button></li>
          <li><button type="button" class="btn btn-link" (click)="redirect('D5')">Instagram</button></li>
        </ul>
      </div>
    </div>
    <h3 class="textcolor text-center">Copyright 2021 Athena Bitcoin Global, All Rights Reserved <br>
      <button type="button" class="btn btn-link" (click)="redirect('E1')">
        <h3>Aviso de privacidad</h3>
      </button>
    </h3>
  </div>
</footer>


Termina el Footer de Dani-->
