import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { TypeIcon } from '../../components/icon-animation/icon-animation.component';
import { AWSSdkService } from 'src/app/services/aws-sdk.service';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
})
export class InfoCardComponent {
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() type: TypeIcon = 'success';

  constructor(private router: Router, private aws: AWSSdkService) {}

  async redirectToLanding() {
    // logout
    await Auth.signOut();
    this.aws.cleanData();
    this.router.navigate(['/']);
  }
}
