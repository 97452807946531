<svg class="checkmark error" viewBox="0 0 52 52" *ngIf="typeIcon === 'error'">
  <circle class="checkmark_circle_error" cx="26" cy="26" r="25" fill="none" />
  <path
    class="checkmark_check"
    stroke-linecap="round"
    fill="none"
    d="M16 16 36 36 M36 16 16 36
    "
  />
</svg>

<svg
  class="checkmark warning"
  viewBox="0 0 52 52"
  *ngIf="typeIcon === 'warning'"
>
  <circle class="checkmark_circle_warning" cx="26" cy="26" r="25" fill="none" />
  <path
    class="checkmark_check"
    stroke-linecap="round"
    fill="none"
    d="M14.1 27.2l7.1 7.2 16.7-16.8"
  />
</svg>

<svg
  class="checkmark success"
  viewBox="0 0 52 52"
  *ngIf="typeIcon === 'success'"
>
  <circle class="checkmark_circle_success" cx="26" cy="26" r="25" fill="none" />
  <path
    class="checkmark_check"
    stroke-linecap="round"
    fill="none"
    d="M14.1 27.2l7.1 7.2 16.7-16.8"
  />
</svg>
