<p-dialog
  header="Términos y Condiciones"
  [(visible)]="showDialog"
  [style]="{ width: '80vw' }"
  [closable]="true"
  [closeOnEscape]="true"
  [maximizable]="true"
  (onHide)="hideTerms()"
>
  <div class="tycbox">
    <div class="w-full flex justify-center">
      <img
        src="assets/img/athena_bitcoin_logo_white.png"
        alt="Athena.png"
        width="160"
      />
    </div>
    <p class="text-center py-4">
      ¡Gracias por utilizar un cajero automático Athena Bitcoin!
    </p>
    <p>
      <span class="text-athena-orange font-medium"> ADVERTENCIA </span>
      <br />
      <br />
      Por favor NO PERMITA a nadie más usar su cuenta. NO utilice este cajero
      automático para enviar dinero a una agencia gubernamental, seguridad
      social, policía o servicios públicos locales o a persona que no conoce
      personalmente; recuerde que todas las operaciones con activos virtuales
      son DEFINITIVAS e IRREVERSIBLES. Siempre utilice una dirección de
      billetera controlada exclusivamente por Usted.
      <br />
      <br />
      Si está realizando la transacción a nombre o por instrucciones de un
      tercero, por favor NO CONTINUE con la operación y ESCRIBANOS AL
      <a
        target="_blank"
        href="https://athena.zendesk.com/hc/es-419/requests/new"
        >SERVICIO DE SOPORTE</a
      >
      <br />
      <br />
      <span class="text-athena-orange font-medium">
        TERMINOS Y CONDICIONES
      </span>
      <br />
      <br />
      Athena Bitcoin, S. de R.L. de C.V, sociedad mercantil constituida conforme
      a las leyes de los Estados Unidos Mexicanos en adelante (“Athena Bitcoin”,
      “Athena” o la “Compañía”), de manera habitual y profesional ofrece la
      compra y venta de activos virtuales (Bitcoin) a través de sus cajeros
      automáticos en adelante (los “Servicios”) de conformidad con los
      siguientes términos y condiciones, en adelante (T&C):
      <br />
      <br />
      Para acceder y utilizar los Servicios de Athena Bitcoin, el Usuario se
      obliga a proporcionar la información personal requerida por los cajeros al
      momento de abrir la cuenta. Athena se reserva el derecho a solicitar
      información al cliente, fijar límites a las transacciones, rechazar
      cualquier operación en caso de ser sospechosa, así como, modificar
      cualquier requisito para apertura de cuentas, modificar los límites de
      transacción diarias, mensuales o anuales de conformidad con sus políticas
      internas. Así mismo, la Compañía analizará y validará la información
      suministrada por el Usuario de manera directa o con el uso de servicios de
      terceros en adelante (“Proceso de Verificación”). Athena se reserva el
      derecho de contactar al Usuario por cualquier medio y en cualquier momento
      para verificar o pedir la aclaración de la información entregada o
      solicitar información adicional sobre los datos entregados, la
      transacción, el origen de los fondos, etc, de conformidad con lo
      establecido en la la Ley Federal para la Prevención e Identificación de
      Operaciones con Recursos de Procedencia Ilícita. La negativa del Usuario a
      proveer dicha información, hacer las aclaraciones pertinentes o la
      confirmación de la entrega de información falsa, inexacta, alterada, o de
      propiedad de otra persona, habilitará a Athena a suspender los servicios
      al Usuario y/o congelar sus transacciones pendientes y futuras, sin
      perjuicio de informar a las autoridades competentes. Así mismo, el Usuario
      acepta seguir todas las instrucciones que le dé el cajero automático,
      obedecer todas las restricciones, incluidos los límites de compra. Athena
      se reserva el derecho sin previo aviso y a su entera discreción de
      rescindir el permiso para usar los servicios a cualquier persona en
      cualquier momento y a tomar medidas para impedir el uso de nuestros
      servicios a aquellos que infrinjan las normas locales y nuestras políticas
      internas.
      <br />
      <br />
      Athena Bitcoin declara que habilita el uso de cajeros para la compra y
      venta de activos virtuales cuya operación se hace de manera instantánea y
      por lo tanto, en ningún momento retiene o custodia los activos virtuales o
      dinero fiat de sus Usuarios, razón por la cual, Athena no es una
      Institución de Tecnología Financiera en los términos de la “Ley Fintech”.
      <br />
      <br />
      El Usuario declara que entiende que la única responsabilidad de Athena
      Bitcoin es entregar el activo virtual a la dirección de la billetera que
      el Usuario suministró en el cajero. El Usuario es responsable de verificar
      que la dirección que aparece en la pantalla del cajero sea la correcta. El
      envío de los recursos a una dirección diferente, en ningún caso será
      responsabilidad de la Compañía.
      <br />
      <br />
      Athena manifiesta que los servicios de "Compra" y "Venta” de activos
      virtuales incluyen nuestra tarifa de intercambio para los cajeros
      automáticos. En todo caso, Athena se reserva el derecho de modificar
      dichas tarifas en cualquier momento, salvo en aquellas transacciones que
      fueron finalizadas. Igualmente, el Usuario acepta que Athena Bitcoin puede
      deducir sus tarifas de la cantidad de dinero fiat o moneda virtual que se
      le envíe.
      <br />
      <br />
      Los reembolsos que se puedan hacer por cheque tendrán un cargo de 100
      dólares o del 15%, lo que sea mayor. También tardarán hasta 60 días
      hábiles.
      <br />
      <br />
      El Usuario acepta toda la responsabilidad por cualquier transacción
      realizada a través de nuestros cajeros. Los activos virtuales son
      altamente volátiles por naturaleza, por lo que Athena recomienda que el
      Usuario se informe ampliamente sobre las inversiones en criptoactivos,
      previo a la realización de cualquier transacción en nuestros cajeros. Las
      recomendaciones, instrucciones, advertencias o indicaciones dadas por
      Athena o sus empleados, en ningún caso, serán consideradas como promoción
      o asesorías de inversión.
      <br />
      <br />
      Periódicamente, Athena Bitcoin podrá publicar anuncios relacionados con
      servicios y/o activos virtuales. No obstante, la Compañía no hace ninguna
      representación o garantía con respecto a los productos anunciados, y se
      exime totalmente de cualquier responsabilidad derivada de los mismos.
      Ningún anuncio de Athena constituye una "oferta pública" o de
      "asesoramiento de inversión" o recomendaciones "personalizadas”.
      <br />
      <br />
      El Usuario declara que:
      <br />
      <br />
      <strong class="text-athena_navy">1)</strong> NO utilizará los Cajeros
      Automáticos de ninguna manera que pueda interferir, interrumpir, afectar
      negativamente o inhibir a otros usuarios de disfrutar plenamente de los
      Servicios de Athena Bitcoin, o que pueda dañar, deshabilitar, sobrecargar
      o perjudicar el funcionamiento de estos Servicios de cualquier manera,
      incluido cualquier daño físico o desfiguración del Cajero Automático.
      <br />
      <br />
      <strong class="text-athena_navy">2)</strong> NO utilizará el cajero
      automático para pagar, apoyar o participar en actividades ilegales,
      incluidos, entre otros, juegos de azar ilegales; música, películas u otros
      contenidos adquiridos ilegalmente; materiales o servicios de índole
      sexual; fraude; lavado de dinero; financiamiento del terrorismo; o la
      compra o venta de sustancias ilegales o controladas.
      <br />
      <br />
      <strong class="text-athena_navy">3)</strong> NO utilizará ningún hardware
      o software para eludir, deshabilitar o interferir con los cajeros
      automáticos, o el proceso de verificación, ni usará o intentará usar la
      cuenta de otro usuario.
      <br />
      <br />
      <strong class="text-athena_navy">4)</strong> NO UTILIZARÁ información
      falsa, inexacta o engañosa en ninguno de nuestros cajeros.
      <br />
      <br />
      El Usuario al hacer uso de cualquier cajero automático de Athena Bitcoin,
      declara y garantiza que:
      <br />
      <br />
      <strong class="text-athena_navy">1)</strong> Es mayor de edad para
      constituir un contrato vinculante.
      <br />
      <br />
      <strong class="text-athena_navy">2)</strong> Que no ha sido suspendido o
      eliminado previamente del uso de los Servicios.
      <br />
      <br />
      <strong class="text-athena_navy">3)</strong> Que no ha sido identificado
      como un "Nacional Especialmente Designado" por la Oficina de Control de
      Activos Extranjeros de los Estados Unidos ('OFAC') ni se encuentra en
      ninguna otra lista negra.
      <br />
      <br />
      <strong class="text-athena_navy">4)</strong> El usuario Declara que los
      fondos utilizados para la compra o venta de activos virtuales son de su
      propiedad y no pertenecen a ningún tercero así mismo se compromete a
      informar a Athena Bitcoin que si los fondos pertenecen a un tercero se
      obliga a presentar un Poder Notarial donde es autorizado a transaccionar
      con dichos fondos.
      <br />
      <br />
      El Usuario declara que es de su conocimiento que las transacciones de
      activos virtuales son públicas en la medida en que se almacenan y son
      confirmadas en el libro mayor público de la criptomoneda utilizando la red
      blockchain de consenso distribuida para esa moneda virtual. En ese
      sentido, para las transacciones de compra y venta, Athena Bitcoin empleará
      sus recursos y conocimientos para la remisión del activo virtual a la
      dirección proporcionada por el Usuario dentro de un plazo razonable sujeto
      siempre al tráfico o saturación de la red de consenso distribuida,
      manteniendo transparencia en el hash generado por dicha transacción. De
      igual manera el Usuario reconoce y acepta que Athena Bitcoin no es
      responsable de los plazos de confirmación que la red de consenso
      distribuida demande, de igual forma declara libre y solvente a Athena
      Bitcoin en los casos en que el Usuario proporcione direcciones o
      billeteras erróneas durante un proceso de compra o venta de activos
      virtuales. De igual forma, la cantidad de activos virtuales que se
      transmiten a su dirección o billetera no será diferente de la cantidad
      indicada en el recibo de venta que se le proporcione con su orden de
      transacción. En todo caso, el Usuario debe tener en cuenta que en
      ocasiones las plataformas como casas de cambio y billeteras podrán cobrar
      tarifas por su uso, dichos cobros no serán responsabilidad de Athena
      Bitcoin.
      <br />
      <br />
      Athena no será responsable por los cortes de luz o la interrupción del
      servicio de internet necesarios para la normal operación de los cajeros y
      que puedan afectar o imposibilitar la finalización de transacciones por
      parte del Usuario.
      <br />
      <br />
      El Usuario es responsable de cualquier impuesto sobre ventas, uso, valor
      agregado o transferencia similar que se imponga en relación con el
      Servicio. Athena Bitcoin se reserva el derecho de cobrarle o recuperar
      dichos impuestos en cualquier momento cuando sea requerido o permitido.
      <br />
      <br />
      Los servicios de Athena Bitcoin requieren el uso de un teléfono móvil con
      capacidad de mensajes de texto SMS. Al proporcionar su número de teléfono
      móvil a Athena Bitcoin al comienzo de una transacción, usted está optando
      específicamente por recibir mensajes de texto SMS de nosotros por las
      siguientes razones:
      <br />
      <br />
      <strong class="text-athena_navy">1)</strong> Activar el Terminal Athena
      Bitcoin para procesar una transacción.
      <br />
      <br />
      <strong class="text-athena_navy">2)</strong> Recibir un código de texto de
      un solo uso (SMS) para volver a introducirlo en el terminal ya sea para
      continuar la transacción actual o canjear una transacción anterior; usted
      elegirá si desea que su recibo se imprima o envíe por correo electrónico.
      Nuestras máquinas bidireccionales permiten un recibo impreso, además de
      opciones de correo electrónico. Su recibo incluirá el tipo de cambio
      utilizado para calcular el valor de la moneda virtual o dinero fiat que
      compró o vendió.
      <br />
      <br />
      En ningún caso Athena Bitcoin, nuestros directores, miembros, empleados o
      agentes serán responsables de ningún daño especial, indirecto o
      consecuente, o cualquier otro daño de ningún tipo, incluidos, entre otros,
      la pérdida de uso, la pérdida de ganancias o la pérdida de datos, ya sea
      en una acción contractual, agravio (incluida, entre otras, negligencia) o
      de otro modo, que surja de o esté relacionado de alguna manera con el uso
      o la incapacidad de usar nuestro cajero automático o servicios o cualquier
      transacción de compra o venta que ocurra a través de un cajero automático,
      incluidos, entre otros, los daños causados por o resultantes de la
      confianza de cualquier usuario en cualquier información obtenida de la
      compañía, o que resulte de errores, omisiones, interrupciones, eliminación
      de archivos o correo electrónico, errores, defectos, virus, retrasos en la
      operación o transmisión o cualquier falla de rendimiento, ya sea que
      resulte o no de actos de dios, falla de comunicaciones, robo, destrucción
      o acceso no autorizado a los registros, programas o servicios de la
      compañía.
      <br />
      <br />
      <span class="text-athena-orange font-medium"> AVISO DE PRIVACIDAD </span>
      <br />
      <br />
      Así mismo, mediante la aceptación de los presentes Términos y condiciones,
      el Usuario autoriza a Athena Bitcoin para recolectar, conservar o
      rectificar la información obtenida por el uso de nuestros cajeros. La
      finalidad de la recolección de los datos es:
      <br />
      <br />
      <strong class="text-athena_navy">1)</strong> La identificación de sus
      clientes y transacciones.
      <br />
      <br />
      <strong class="text-athena_navy">2)</strong> El cumplimiento de
      obligaciones de reportería ante el Servicio de la Administración
      Tributaria (SAT).
      <br />
      <br />
      <strong class="text-athena_navy">3)</strong> Para dar a conocer los
      servicios ofrecidos por Athena Bitcoin y/o sus filiales y/o subsidiarias.
      El Usuario tiene derecho a conocer, actualizar y rectificar sus datos
      personales y, sólo en los casos en que sea procedente, a suprimirlos o
      revocar la autorización otorgada para su tratamiento. Si desea presentar
      una consulta, reclamo o petición de información relacionada con la
      protección de datos personales por favor escribir al
      <a
        target="_blank"
        href="https://athena.zendesk.com/hc/es-419/requests/new"
        >formulario de soporte</a
      >
      <br />
      <br />
      Al seleccionar la opción "Estoy de acuerdo" estás aceptando todos los
      Términos de Servicio de Athena Bitcoin, S. de R.L. de C.V, publicados aquí
      y en
      <a
        target="_blank"
        class="text-blue-600 underline decoration-1 hover:cursor-pointer"
        href="https://www.athenabitcoin.com"
        >ATHENA BITCOIN</a
      >
      <br />
      <br />
      ¿Preguntas? Por favor visite nuestra página web
      <a
        target="_blank"
        class="text-blue-600 underline decoration-1 hover:cursor-pointer"
        href="https://www.athenabitcoin.com"
        >ATHENA BITCOIN</a
      >
    </p>
  </div>
</p-dialog>
