import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent {
  @Input() showDialog: boolean = false;
  @Output() closeDialgo = new EventEmitter<boolean>();
  constructor() {}

  hideTerms() {
    this.closeDialgo.emit(false);
  }
}
