import { EventEmitter, Injectable, Output } from '@angular/core';
import { AWSSdkService } from './aws-sdk.service';
import { Auth } from 'aws-amplify';
import { MessageService } from 'primeng/api';
import { MESSAGE_SERVICE } from '../constants/global-constants';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationCodeService {
  @Output() resetCodeAttempts = new EventEmitter<number>();
  @Output() resetCodeValue = new EventEmitter<string>();
  counter = 0;
  interval: any;
  resendBtnDisabled = true;
  timer: any;

  constructor(
    private aws: AWSSdkService,
    private messageService: MessageService
  ) {}

  // Resend the sms to signup process
  async resendSignupCode() {
    const params = {
      username: this.aws.getUserPhone(),
    };
    await Auth.resendSignUp(params.username)
      .then(() => {
        this.resetCodeAttempts.emit(0);
        this.messageService.add({
          severity: 'success',
          detail: `${
            MESSAGE_SERVICE.new_confirmation_code
          } ${this.aws.getUserPhone()}`,
        });
        return true;
      })
      .catch((error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `${MESSAGE_SERVICE.confirmation_code_no_send}`,
        });
        return false;
      });
  }

  // Resend the sms to login process
  async resendLoginCode() {
    Auth.signIn(this.aws.getUserPhone())
      .then((res) => {
        this.resetCodeAttempts.emit(0);
        this.aws.setCognitoUser(res);
        this.messageService.add({
          severity: 'success',
          detail: `${
            MESSAGE_SERVICE.new_confirmation_code
          } ${this.aws.getUserPhone()}`,
        });
      })
      .catch((error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `${MESSAGE_SERVICE.confirmation_code_no_send}`,
        });
      });
  }

  // Control the time to enable the button for resending the confirm sms
  resendStatusBtnDisbled(seconds: number) {
    this.counter = seconds;
    seconds = seconds * 1000;
    this.resendBtnDisabled = true;

    if (this.interval || this.timer) {
      clearInterval(this.interval);
      clearInterval(this.timer);
    }

    // manage the counter
    this.interval = setInterval(() => {
      if (this.counter > 0) {
        this.counter -= 1;
      } else if (this.counter === 0) {
        clearInterval(this.interval);
        clearInterval(this.timer);
      }
    }, 1000);

    // clean the interval
    this.timer = setTimeout(() => {
      this.resendBtnDisabled = false;
      clearInterval(this.interval);
    }, seconds);
  }

  resendSmsCodeSignup() {
    this.resendSignupCode();
    this.resendStatusBtnDisbled(40);
  }

  resendSmsCodeLogin() {
    this.resendLoginCode();
    this.resendStatusBtnDisbled(40);
  }
}
