<div class="w-full">
  <p class="text-justify pb-5">
    Si possees algún problema o consulta respecto al proceso de registro
    comunícate con nosotros, será un gusto atenderte.
  </p>
  <div
    class="grid grid-cols-1 md:grid-cols-2 w-full justify-center items-center gap-3"
  >
    <p
      (click)="redirectToAthenaWeb()"
      class="font-medium col-span-2 sm:col-span-1 flex justify-center items-center gap-3 p-3 bg-white/10 text-athena-yellow/80 rounded-[10px] hover:cursor-pointer transition-all duration-300"
    >
      <i class="pi pi-desktop text-white"> </i>
      Athena Bitcoin
    </p>
    <p
      class="font-medium col-span-2 sm:col-span-1 flex justify-center items-center gap-3 p-3 bg-white/10 text-athena-yellow/80 rounded-[10px] hover:cursor-default transition-all duration-300"
    >
      <i class="pi pi-phone text-white"> </i>
      +52 55 4161 1429
    </p>
  </div>
</div>
