import { Component } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent {
  redirectToAthenaWeb() {
    window.open('https://athena.zendesk.com/', '_blank');
  }
}
