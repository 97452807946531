import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tag-store',
  templateUrl: './tag-store.component.html',
  styleUrls: ['./tag-store.component.scss']
})
export class TagStoreComponent {
  @Input() title: string;
  @Input() icon: string;
  @Input() url?: string;

  redirectToStore() {
    if (this.url) {
      window.open(this.url, '_blank');
    }
  }
}
