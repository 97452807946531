import { Injectable } from '@angular/core';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { UserAttributes } from '../interfaces/global-interfaces';

@Injectable({
  providedIn: 'root',
})
export class AWSSdkService {
  private cognitoUser!: CognitoUser | null;
  private phone: string = '';
  private tokenCognitoUser: string;
  private isUserPhoneConfirmed: boolean | null = null;
  private cognitoUserAttributes: UserAttributes;

  constructor() {}

  setUserPhone(userphone: string) {
    this.phone = userphone;
  }

  getUserPhone() {
    return this.phone;
  }

  setCognitoUser(value: CognitoUser) {
    this.cognitoUser = value;
  }

  getCognitoUser() {
    return this.cognitoUser;
  }

  setTokenUser(user: any) {
    this.tokenCognitoUser = user?.signInUserSession?.idToken?.jwtToken;
  }

  getTokenUser() {
    return this.tokenCognitoUser;
  }

  setIsUserPhoneConfirmed(value: boolean | null) {
    this.isUserPhoneConfirmed = value;
  }

  getIsUserPhoneConfirmed() {
    return this.isUserPhoneConfirmed;
  }

  setcognitoUserAttributes(userAttributes: UserAttributes) {
    this.cognitoUserAttributes = userAttributes;
  }

  getcognitoUserAttributes() {
    return this.cognitoUserAttributes;
  }

  cleanData() {
    this.cognitoUser = null;
    this.phone = '';
    this.tokenCognitoUser = '';
    this.isUserPhoneConfirmed = false;
  }
}
