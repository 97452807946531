import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { ConfirmationCodeService } from 'src/app/services/confirmation-code.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent
  implements AfterViewInit, OnInit, OnDestroy, AfterViewInit
{
  @Output() code = new EventEmitter();
  @Output() confirmCode = new EventEmitter();
  @Output() cancelSignin = new EventEmitter();
  @Input() loading: boolean = false;
  @Input() login: boolean = false;
  @ViewChild('otp') otpComponent: ElementRef;
  invalidCode: boolean = false;
  codeForm: FormGroup;
  subscriptions: Subscription[] = [];
  isMobile: boolean = false;

  constructor(
    public confirmationCodeService: ConfirmationCodeService,
    private formBuilder: FormBuilder,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit(): void {
    this.isMobile = this.deviceService.isMobile();
    this.initCodeForm();
    // Reset top value
    document.body.style.top = '';
    this.subscriptions.push(
      this.confirmationCodeService.resetCodeValue.subscribe((value) => {
        // clear code
        this.codeForm.get('sms')?.setValue(value);
      })
    );
  }
  // Sets a init value for confirmation-code form
  initCodeForm() {
    this.codeForm = this.formBuilder.group({
      sms: ['', [Validators.required, Validators.minLength(7)]],
    });
  }

  ngAfterViewInit(): void {
    if (!this.isMobile) {
      // Set the scroll position fixed
      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.innerHeight + 100000}px`;
      // Reset scroll
      document.body.style.position = '';
    }
    this.otpComponent.nativeElement.focus();
  }

  // Gets changes in otp component when the user is entering the confirmation code
  onChangeOtp(event: any) {
    const code = event.target.value;
    this.codeForm.get('sms')?.setValue(code);

    if (this.codeForm.get('sms')?.value.length < 7) {
      this.codeForm.get('sms')?.setErrors({ minlength: true });
      this.codeForm.markAllAsTouched();
    } else {
      this.codeForm.get('sms')?.setErrors(null);
      this.codeForm.markAsUntouched();
    }

    if (code.length === 7) {
      const formatCode = code.replace(/\s+/g, '');
      this.code.emit({ valid: true, code: formatCode });
    } else {
      this.code.emit({ valid: false, code: null });
    }
  }

  cancelConfirmSignIn() {
    this.cancelSignin.emit();
  }

  confirmSMSCode() {
    this.confirmCode.emit();
  }

  resendCode() {
    if (this.login) {
      this.confirmationCodeService.resendSmsCodeLogin();
    } else {
      this.confirmationCodeService.resendSmsCodeSignup();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((element) => {
      element.unsubscribe();
    });
  }
}
