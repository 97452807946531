import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Progress } from 'src/app/interfaces/global-interfaces';

@Component({
  selector: 'app-circle-progress',
  templateUrl: './circle-progress.component.html',
  styleUrls: ['./circle-progress.component.scss'],
})
export class CircleProgressComponent {
  @Input() step = 1;
  @Input() title: string;
  @Input() previousStepTitle: string = '';
  @Input() progress: Progress[] = [];
  @Output() $previousStep = new EventEmitter();

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.changeDetector.detectChanges();
  }

  ngOnInit(): void {}

  previousStep() {
    this.$previousStep.emit();
  }
}
