// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  aws_cognito_identity_pool_id:
    'us-east-1:1c979b8e-d99c-4749-8f02-5cbb56247533',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_3DCuGDcGQ',
  aws_user_pools_web_client_id: 'mskf22vlcsktae7b83s58irds',
  api_endpoint_kyc: 'https://api2.kyc.responsible-ice-advertise-neighbor.link/',
  siteKey: '6Lesc9clAAAAAFvD8xTz7vjma6hBdjNM0DQJVsNI',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
