import { Component,Input } from '@angular/core';
import { VERSION } from '../../environments/version';
@Component({
  selector: 'app-footer ',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  @Input() version: string = VERSION.version;
  @Input() hash: string = VERSION.hash;

  redirect(URL: string) {
    switch (URL) {
      case 'A1':
        window.location.href = "https://athenabitcoin.com/es/about-es/"
        break;
      case 'A2':
        window.location.href = "https://athenabitcoin.com/ir-contact"
        break;
      case 'A3':
        window.location.href = "https://athenabitcoin.com/es/careers-es"
        break;
      case 'A4':
        window.location.href = "https://athenabitcoin.com/es/press-and-media-es"
        break;
      case 'A5':
        window.location.href = "https://athenabitcoin.com/commitment-to-security/"
        break;
      case 'A6':
        window.location.href = "https://athenabitcoin.com/politica-tratamiento-de-datos-colombia-es"
        break;
      case 'B1':
        window.location.href = "https://athenabitcoin.com/es/ruru-es/"
        break;
      case 'B2':
        window.location.href = "https://athenabitcoin.com/es/locations-map-es"
        break;
      case 'B3':
        window.location.href = "https://athenacryptoexchange.com/"
        break;
      case 'B4':
        window.location.href = "https://www.athenacryptoexchange.com/es/ace-vida-sv-es/"
        break;
      case 'B5':
        window.location.href = "https://athenabitcoin.com/experience-center/"
        break;
      case 'B6':
        window.location.href = "https://athenabitcoin.com/athena-pay-es"
        break;
      case 'C1':
        window.location.href = "https://athenabitcoin.com/es/atm-information-es"
        break;
      case 'C2':
        window.location.href = "https://athenabitcoin.com/es/atm-locations-es"
        break;
      case 'C3':
        window.location.href = "https://athenabitcoin.com/es/faq-es/"
        break;
      case 'D1':
        window.location.href = "https://athenabitcoin.com/contacto"
        break;
      case 'D2':
        window.location.href = "https://support.athenabitcoin.com/en/support/tickets/new"
        break;
      case 'D3':
        window.location.href = "https://twitter.com/AthenaBitcoin"
        break;
      case 'D4':
        window.location.href = "https://www.linkedin.com/company/athenabitcoin/"
        break;
      case 'D5':
        window.location.href = "https://www.instagram.com/athenabitcoin/"
        break;
      case 'E1':
        window.location.href = "https://athenabitcoin.com/es/privacy-policy-es/"
        break;
    }
  }
}

