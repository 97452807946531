import { CircleProgressComponent } from './components/circle-progress/circle-progress.component';
import { CommonModule } from '@angular/common';
import { IconAnimationComponent } from './components/icon-animation/icon-animation.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgModule } from '@angular/core';
import { OtpComponent } from './components/otp/otp.component';
import { PrimengModule } from '../primeng/primeng.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TagStoreComponent } from './components/tag-store/tag-store.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { ContactComponent } from './components/contact/contact.component';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { ConfirmationCodeDirective } from './directives/confirmation-code.directive';
import { EmptyStringDirective } from './directives/empty-string.directive';

@NgModule({
  declarations: [
    CircleProgressComponent,
    IconAnimationComponent,
    LoaderComponent,
    OtpComponent,
    TagStoreComponent,
    TermsAndConditionsComponent,
    PhoneMaskDirective,
    ContactComponent,
    InfoCardComponent,
    ConfirmationCodeDirective,
    EmptyStringDirective,
  ],
  imports: [
    CommonModule,
    NgCircleProgressModule,
    ReactiveFormsModule,
    RouterModule,
    PrimengModule,
  ],
  exports: [
    CircleProgressComponent,
    IconAnimationComponent,
    LoaderComponent,
    NgCircleProgressModule,
    OtpComponent,
    PrimengModule,
    ReactiveFormsModule,
    TagStoreComponent,
    TermsAndConditionsComponent,
    PhoneMaskDirective,
    ContactComponent,
    InfoCardComponent,
    ConfirmationCodeDirective,
    EmptyStringDirective,
  ],
})
export class SharedModule {}
