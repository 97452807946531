<div class="min-h-screen w-full flex justify-center items-center">
  <div
    class="w-4/5 sm:w-4/5 md:w-3/5 lg:w-2/6 xl:w-2/6 animate-fade bg-slate-400/40 rounded-xl flex flex-col custom-shadow"
  >
    <app-icon-animation [typeIcon]="type"></app-icon-animation>

    <div
      class="bg-white/70 rounded-t-[100%] rounded-b-3xl pt-8 pb-5 px-5 flex flex-col justify-center items-center gap-3"
    >
      <a href="/" class="h-[100px]">
        <img
          src="assets/img/athena_bitcoin_logo.png"
          alt="Athena.png"
          width="100"
        />
      </a>
      <p
        class="text-base lg:text-xl text-center font-semibold text-athena-navy"
      >
        {{ title }}
      </p>
      <p
        class="sm:text-xs md:text-sm lg:text-base pb-3 text-athena-navy font-medium text-center"
      >
        {{ description }}
      </p>

      <p-button
        label="Entendido"
        styleClass="p-button-secondary"
        (onClick)="redirectToLanding()"
      >
      </p-button>
    </div>
  </div>
</div>
