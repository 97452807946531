import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { FooterComponent } from './footer/footer.component';
import { QRCodeModule } from 'angularx-qrcode';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
} from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { PrimengModule } from './primeng/primeng.module';
import { CircleProgressOptions } from 'ng-circle-progress';
import { SharedModule } from './shared/shared.module';
import { MessageService } from 'primeng/api';

@NgModule({
  declarations: [AppComponent, FooterComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    QRCodeModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    PrimengModule,
    HttpClientModule,
  ],
  providers: [
    HttpClientModule,
    MessageService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.siteKey as string,
      } as RecaptchaSettings,
    },
    { provide: CircleProgressOptions },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
